import React, { Fragment } from "react"
import propTypes from "prop-types"
import deepmerge from "deepmerge"

import HeroContainer from "../../components/containers/hero"
import PullBelowHeader from "../../components/containers/pull-below-header"
import ContentBlock from "../../components/content-block"
import heroBottom from "../../assets/guides-react-hero-bottom.svg"
import reactLogo from "../../assets/guides-react.svg"
import LinkToParentPage from "../../components/link-to-parent-page"
import Title from "../../components/hero/title"
import { guideContainer } from "../../utils/styles"
import {
  mediaQueries,
  transition,
  colors,
  fontSizes,
  space,
} from "../../utils/presets"
import { rhythm } from "../../utils/typography"

const styles = {
  heroBottomSvg: {
    bottom: -1,
    display: `block`,
    marginBottom: 0,
    position: `absolute`,
    width: `100%`,
  },
  subheader: {
    color: colors.white,
    fontSize: fontSizes[3],
    marginBottom: space[12],
  },
  reactLogo: {
    height: 400,
    left: -180,
    position: `absolute`,
    top: 0,
    transition: `opacity ${transition.speed.default} ${
      transition.curve.default
    }`,
    width: 400,
    "@media (max-width: 1150px)": {
      opacity: 0.3,
    },
    "@media (max-width: 750px)": {
      display: `none`,
    },
  },
}

const Hero = ({ page }) => {
  const subheaderParagraphs = page.subtitle.subtitle
    .split(`\n`)
    .filter(item => item !== `\\n`)
    .map((item, i) => <p key={i}>{item}</p>)

  return (
    <PullBelowHeader>
      <HeroContainer
        isInverted
        customStyles={{ background: colors.react.dark }}
      >
        <div
          css={{
            position: `relative`,
            marginBottom: rhythm(6),
          }}
        >
          <div
            css={deepmerge(guideContainer, {
              WebkitFontSmoothing: `antialiased`,
              paddingBottom: rhythm(6),
              [mediaQueries.phablet]: {
                paddingBottom: rhythm(8),
              },
              [mediaQueries.desktop]: {
                paddingBottom: rhythm(10),
              },
            })}
          >
            {page.parentPage && (
              <LinkToParentPage contentfulPage={page} isInverted />
            )}
            <Title
              isInverted
              customStyles={{
                marginTop: space[9],
                marginBottom: space[9],
                maxWidth: 500,
              }}
            >
              {page.title}
            </Title>
            <div css={styles.subheader}>
              {subheaderParagraphs.map((p, i) => (
                <Fragment key={i}>{p}</Fragment>
              ))}
              <ContentBlock contentBlock={page.contentBlocks[0]} />
            </div>
          </div>
          <img css={styles.reactLogo} src={reactLogo} alt="React Logo" />
          <img css={styles.heroBottomSvg} src={heroBottom} alt="Shapes" />
        </div>
      </HeroContainer>
    </PullBelowHeader>
  )
}

Hero.propTypes = {
  page: propTypes.object.isRequired,
}

export default Hero
